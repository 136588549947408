import axios from 'axios';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import CreditCardForm from '../../../Components/Common/CreditCardForm';
import LogoSection from '../../../Components/Common/LogoSection';
import Spinner from '../../../Components/Common/Spinner';
import Stepper from '../../../Components/Common/Stepper';
import StepperMobile from '../../../Components/Common/StepperMobile';
import { addVerifyingCard, resetVerifyingCardData } from '../../../Reducers/cardReducer';
import { updateOnboardingStep } from '../../../Reducers/signupReducer';
import { AppDispatch, RootState } from '../../../Store';
import { saveCardDetails, updatePaymentStepAsync } from '../../../Utils/api';
import {
  BUSINESS,
  INDIVIDUAL,
  Moyassar3dSecureResponse,
  MoyassarErrorMessages,
  ONBOARDING_STEPS,
} from '../../../Utils/constants';
import { isExpired } from '../../../Utils/helperFunctions';
import CardComponent from '../../ManagePayment/CardComponent';
import ButtonsSection from '../Common/ButtonsSection';
import CardOptions from './CardOptions';

const PaymentInformation = () => {
  const [cardData, setCardData] = useState({
    name: '',
    number: '',
    cvc: '',
    month: '',
    year: '',
  });

  const [paymentDetails, setPaymentDetails] = useState({
    id: '',
    status: '',
    last4: '',
    type: '',
    expiryDate: '',
  });

  const [error, setError] = useState('');
  const [errorDescription, setErrorDescription] = useState('');
  const [currentUrl, setCurrentUrl] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [verificationUrl, setVerificationUrl] = useState('');
  const [cardApproved, setCardApproved] = useState(false);
  const verifyingCard = useSelector((state: RootState) => state.cards?.verifyingCard);
  const paymentMethod = useSelector(
    (state: RootState) => state.signup?.customer?.customer?.paymentMethod,
  );

  const card = useSelector((state: RootState) => state.cards?.cards[0]);

  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch<AppDispatch>();

  const params = new URLSearchParams(location.search);
  const messages = params.getAll('message');
  const message = messages.length > 0 ? messages[messages.length - 1] : null;
  const ids = params.getAll('id');
  const paymentId = ids.length > 0 ? ids[ids.length - 1] : '';

  useEffect(() => {
    setIsLoading(true);
    const timer = setTimeout(() => {
      setIsLoading(false);
    }, 2000);

    return () => clearTimeout(timer);
  }, []);

  useEffect(() => {
    const handleResponse = () => {
      if (message && message in MoyassarErrorMessages) {
        setError(MoyassarErrorMessages[message]);
        if (message === Moyassar3dSecureResponse.DECLINED_INVALID_PIN) {
          const savedCardData = localStorage.getItem('cardData');
          if (savedCardData) {
            setCardData(JSON.parse(savedCardData));
          }
        }
        dispatch(resetVerifyingCardData());
        localStorage.removeItem('cardData');
      } else if (message === Moyassar3dSecureResponse.APPROVED) {
        setCardApproved(true);
        localStorage.removeItem('cardData');
      }
      setCurrentUrl(window.location.href);
    };
    handleResponse();
  }, [message, dispatch, setCurrentUrl]);

  useEffect(() => {
    if (verifyingCard) {
      const { status, expiryDate, paymentIdentifier, last4, type, cardHolderName } = verifyingCard;

      if (cardApproved && paymentIdentifier.length > 0) {
        dispatch(resetVerifyingCardData());
        dispatch(
          saveCardDetails({
            paymentId: paymentId,
            id: paymentIdentifier,
            last4: last4,
            expiryDate: expiryDate,
            status: status,
            type: type,
            navigate: navigate,
            setIsLoading: setIsLoading,
            setErrorMessage: setError,
            setErrorDescription,
            isAdmin: false,
            customerId: undefined,
            isOnboarding: true,
            cardHolderName,
          }),
        );
      }
    }
  }, [cardApproved]);

  useEffect(() => {
    setPaymentDetails((prevState) => ({
      ...prevState,
      id: (card?.id || paymentMethod?.id?.toLocaleString()) ?? '',
      status: (card?.status || paymentMethod?.status) ?? '',
      last4: (card?.last4 || paymentMethod?.last4) ?? '',
      type: (card?.type || paymentMethod?.type) ?? '',
      expiryDate: card?.expiryDate || moment(paymentMethod?.expiryDate).format('MM/YY') || '',
    }));
    setCurrentUrl(window.location.href);
  }, []);

  useEffect(() => {
    if (verificationUrl) {
      //direct to 3d secure url
      window.open(verificationUrl, '_self');
    }
  }, [verificationUrl]);

  const accountType = useSelector((state: RootState) => state.signup.accountType);

  const handleBack = () => {
    navigate('/signup');

    if (accountType === BUSINESS) {
      dispatch(updateOnboardingStep(ONBOARDING_STEPS.BILLING_EMAIL));
    } else {
      dispatch(updateOnboardingStep(ONBOARDING_STEPS.SUPPORT_PACKAGE));
    }
  };

  const handleNext = () => {
    if (paymentDetails.last4.length === 4) {
      navigate('/signup');

      dispatch(updatePaymentStepAsync(setIsLoading, setError, navigate));
    } else {
      handleSave();
    }
  };

  const handleSave = () => {
    const currentDate = new Date();
    const currentYear: string = currentDate.getFullYear().toString().slice(-2);
    const currentMonth: number = currentDate.getMonth() + 1;
    if (cardData.number.length !== 16 && cardData.number.length !== 15)
      setError('Invalid card number');
    else if (cardData.cvc.length !== 3) setError('Invalid CVV');
    else if (!cardData.name.includes(' ')) setError('Invalid Card Holder Name');
    else if (Number(cardData.month) > 12) setError('Invalid month');
    else if (Number(cardData.year) < Number(currentYear)) setError('Invalid year');
    else if (Number(cardData.year) === Number(currentYear) && Number(cardData.month) < currentMonth)
      setError('Card has expired');
    else {
      setIsLoading(true);

      const apiUrl = 'https://api.moyasar.com/v1/tokens';
      const config = {
        auth: {
          username: process.env.REACT_APP_MOYASAR_PUBLIC_KEY ?? '',
          password: '',
        },
        body: {
          ...cardData,
          callback_url: currentUrl,
        },
      };
      axios
        .post(apiUrl, config.body, { auth: config.auth })
        .then((response) => {
          const expiryDate = `${response.data.month}/${response.data.year.slice(-2)}`;
          const cardCredentials = {
            id: response.data?.id,
            status: 'primary',
            paymentIdentifier: response.data?.id,
            last4: response.data?.last_four,
            expiryDate,
            type: response.data?.brand || 'visa',
            cardHolderName: response.data?.name,
          };

          if (response.data.verification_url === null) {
            setIsLoading(false);
            setError(response.data.message || 'An error occurred during verification.');
            return;
          }
          if (cardCredentials.type === 'mada') {
            setIsLoading(false);
            setError('Mada cards are not accepted, please add an other card.');
            return;
          }
          localStorage.setItem('cardData', JSON.stringify(cardData));
          dispatch(addVerifyingCard(cardCredentials));
          setVerificationUrl(response.data.verification_url);
        })
        .catch((error) => {
          let errorMessage = error?.response?.data?.message || 'Invalid card details';
          errorMessage = errorMessage.includes(':')
            ? errorMessage.split(':')[1].trim()
            : errorMessage;
          setError(errorMessage);
          setIsLoading(false);
        });
    }
  };
  return (
    <div className="w-full h-full flex items-stretch lg:items-center justify-center">
      {isLoading && <Spinner />}
      {!isLoading && (
        <div className="border-[1px] border-gray-3 rounded-lg py-4 px-8 bg-white-0 min-h-[680px] w-[98%] overflow-auto flex flex-col justify-between">
          <LogoSection />
          {accountType === BUSINESS ? (
            <Stepper type={BUSINESS} currentStep={5} />
          ) : (
            <Stepper type={INDIVIDUAL} currentStep={3} />
          )}
          <StepperMobile
            stepNumber={accountType === BUSINESS ? 5 : 3}
            stepName="Payment Information"
            nextStep=""
            totalSteps={accountType === BUSINESS ? 5 : 3}
          />
          <div className="flex gap-2 items-center my-5">
            <h1 className="text-gray-1 text-lg poppins-500 whitespace-nowrap">
              Payment Information
            </h1>
            <div className="w-full h-[1px] bg-gray-2"></div>
          </div>
          <div className="flex flex-col md:flex-row justify-between">
            <div className="flex flex-col justify-center items-baseline text-left mb-auto md:w-2/5 w-full">
              {paymentDetails.last4.length === 4 ? (
                <CardComponent
                  key={paymentDetails.id}
                  id={paymentDetails.id}
                  title={paymentDetails.status}
                  cardDetails={`${paymentDetails.type || ''} ****-${paymentDetails.last4}`}
                  expiryDate={`Expiry ${paymentDetails.expiryDate}`}
                  dashedBorder={true}
                  setIsLoading={setIsLoading}
                  setError={setError}
                  isExpired={isExpired(paymentDetails.expiryDate)}
                />
              ) : (
                <CreditCardForm
                  cardData={cardData}
                  setCardData={setCardData}
                  isSaveButton={false}
                  setError={setError}
                  error={error}
                />
              )}
            </div>
            <CardOptions isOnBoarding={true} />
          </div>
          <div className="mt-auto">
            <ButtonsSection
              errorMessage={error || ''}
              errorDescription={errorDescription || ''}
              isBackButton={true}
              isNextButton={true}
              nextFunction={handleNext}
              backFunction={handleBack}
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default PaymentInformation;
